var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.profile
    ? _c(
        "div",
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t("Email"),
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "email",
                          {
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("Please input your E-mail!")
                              },
                              {
                                type: "email",
                                message: _vm.$t(
                                  "The input is not valid E-mail!"
                                )
                              }
                            ],
                            initialValue: _vm.profile.email
                          }
                        ],
                        expression:
                          "[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('Please input your E-mail!')\n              },\n              {\n                type: 'email',\n                message: $t('The input is not valid E-mail!')\n              }\n            ],\n            initialValue: profile.email\n          }\n        ]"
                      }
                    ],
                    attrs: {
                      type: "text",
                      disabled: "",
                      placeholder: _vm.$t("Enter your email")
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t("Contact phone"),
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "contact_phone",
                          {
                            initialValue: _vm.profile.contact_phone,
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("Please enter a phone number")
                              },
                              { validator: _vm.validatorPhone }
                            ]
                          }
                        ],
                        expression:
                          "[\n          'contact_phone',\n          {\n            initialValue: profile.contact_phone,\n            rules: [\n              { required: true, message: $t('Please enter a phone number') },\n              { validator: validatorPhone }\n            ]\n          }\n        ]"
                      }
                    ],
                    attrs: { placeholder: _vm.$t("Contact phone") }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t("Company name"),
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "company_name",
                          {
                            initialValue: _vm.profile.company_name,
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("Please enter company name")
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n          'company_name',\n          {\n            initialValue: profile.company_name,\n            rules: [{ required: true, message: $t('Please enter company name') }]\n          }\n        ]"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("Company name placeholder")
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t("Person in charge"),
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "person_in_charge",
                          {
                            initialValue: _vm.profile.person_in_charge,
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("Please enter person in charge")
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n          'person_in_charge',\n          {\n            initialValue: profile.person_in_charge,\n            rules: [{ required: true, message: $t('Please enter person in charge') }]\n          }\n        ]"
                      }
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("Person in charge")
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t("Zip code"),
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c(
                    "a-input-search",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "postalcode",
                            {
                              initialValue: _vm.profile.postalcode || "",
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t("Zip code is required")
                                },
                                { validator: _vm.validateZipCode }
                              ]
                            }
                          ],
                          expression:
                            "[\n          'postalcode',\n          {\n            initialValue: profile.postalcode || '',\n            rules: [\n              { required: true, message: $t('Zip code is required') },\n              { validator: validateZipCode }\n            ]\n          }\n        ]"
                        }
                      ],
                      attrs: { placeholder: _vm.$t("Zip code") },
                      on: { search: _vm.onSearch }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            slot: "enterButton",
                            loading: _vm.loading,
                            type: "primary"
                          },
                          slot: "enterButton"
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Search")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t("Company address"),
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "company_prefecture",
                            {
                              initialValue: _vm.profile.company_prefecture,
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t(
                                    "Please enter company prefecture"
                                  )
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n          'company_prefecture',\n          {\n            initialValue: profile.company_prefecture,\n            rules: [{ required: true, message: $t('Please enter company prefecture') }]\n          }\n        ]"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("Prefecture")
                      },
                      on: { change: _vm.changePrefecture }
                    },
                    _vm._l(_vm.prefStates, function(item) {
                      return _c("a-select-option", { key: item }, [
                        _vm._v(" " + _vm._s(item) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": _vm.buttonItemLayout.wrapperCol } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "company_city",
                            {
                              initialValue: _vm.profile.company_city,
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t("Please enter company city")
                                }
                              ]
                            }
                          ],
                          expression:
                            "[\n          'company_city',\n          {\n            initialValue: profile.company_city,\n            rules: [{ required: true, message: $t('Please enter company city') }]\n          }\n        ]"
                        }
                      ],
                      attrs: { type: "text", placeholder: _vm.$t("City") }
                    },
                    _vm._l(_vm.mapCitiesByState, function(item) {
                      return _c("a-select-option", { key: item.cityName }, [
                        _vm._v(" " + _vm._s(item.cityName) + " ")
                      ])
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": _vm.buttonItemLayout.wrapperCol } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "company_address",
                          {
                            initialValue: _vm.profile.company_address,
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("Please enter company address")
                              }
                            ]
                          }
                        ],
                        expression:
                          "[\n          'company_address',\n          {\n            initialValue: profile.company_address,\n            rules: [{ required: true, message: $t('Please enter company address') }]\n          }\n        ]"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t("Company url"),
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "company_url",
                          {
                            rules: [
                              {
                                type: "url",
                                message: _vm.$t("Please input URL format")
                              }
                            ],
                            initialValue: _vm.profile.company_url
                          }
                        ],
                        expression:
                          "[\n          'company_url',\n          {\n            rules: [{ type: 'url', message: $t('Please input URL format') }],\n            initialValue: profile.company_url\n          }\n        ]"
                      }
                    ]
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t("Descriptions"),
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "company_description",
                          {
                            initialValue: _vm.profile.company_description
                          }
                        ],
                        expression:
                          "[\n          'company_description',\n          {\n            initialValue: profile.company_description\n          }\n        ]"
                      }
                    ],
                    attrs: { rows: 3 }
                  })
                ],
                1
              ),
              _vm.type === "register"
                ? _c(
                    "a-form-item",
                    {
                      attrs: { "wrapper-col": _vm.buttonItemLayout.wrapperCol }
                    },
                    [
                      _c(
                        "a-checkbox",
                        {
                          attrs: { defaultChecked: _vm.checked },
                          on: { change: _vm.onChangeCheck }
                        },
                        [
                          _c("span", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://www.uni-voice.co.jp/policies",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("利用規約")]
                            ),
                            _vm._v(" 及び "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "http://uni-voice.co.jp/policy/",
                                  target: "_blank"
                                }
                              },
                              [_vm._v("プライバシーポリシー")]
                            ),
                            _vm._v(" に同意する ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                { attrs: { "wrapper-col": _vm.buttonItemLayout.wrapperCol } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "html-type": "submit",
                        block: "",
                        loading: _vm.loading,
                        disabled: !_vm.checked
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.handleSubmit($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.type === "update"
                              ? _vm.$t("Save")
                              : _vm.$t("Submit")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }