//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
// import Pricing from "@/components/Pricing/Index.vue";
export default {
  // components: {
  //   Pricing
  // },
  props: {
    type: {
      type: String,
      required: true
    },
    profile: {
      type: Object,
      required: true,
      default: () => {}
    },
    formItemLayout: {
      type: Object,
      required: true,
      default: () => {}
    },
    buttonItemLayout: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      checked: true,
      form: this.$form.createForm(this, { name: 'profile_form' }),
      pickState: ''
    };
  },
  computed: {
    ...mapGetters('resource', ['prefStates', 'getCitiesByState']),
    mapCitiesByState() {
      if (!this.pickState) return [];
      return this.getCitiesByState(this.pickState);
    }
  },
  created() {
    this.pickState = this.profile.company_prefecture;
  },
  methods: {
    onChangeCheck({ target }) {
      this.checked = target.checked;
    },
    changePrefecture(value) {
      this.pickState = value;
      this.form.setFieldsValue({
        company_city: ''
      });
    },

    validateZipCode(rule, value, callback) {
      if (value.toString().length !== 7 && value.toString().length !== 0) {
        callback(this.$t('Zip code is required'));
      } else {
        callback();
      }
    },

    handleSubmit() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.type === 'update'
            ? this.$emit('updateProfile', values)
            : this.$emit('register', values);
        }
      });
    },

    validatorPhone(rule, value, callback) {
      const maxLenght = value.toString().length < 10 || value.toString().length > 11;
      if (value && maxLenght) {
        callback(this.$t('InvalidPhone'));
      } else {
        callback();
      }
    },

    onSearch(value) {
      const zipCode = value.toString();
      const preCode = zipCode.slice(0, 3);
      const subCode = zipCode.slice(3, 7);
      this.loading = true;
      fetch(`https://madefor.github.io/postal-code-api/api/v1/${preCode}/${subCode}.json`, {
        method: 'Get'
      }).then(res => {
        if (res.status === 200) {
          res.json().then(data => {
            this.form.setFieldsValue({
              company_prefecture: data.data[0].ja.prefecture,
              company_city: data.data[0].ja.address1,
              company_address: data.data[0].ja.address2
            });
          });
        } else {
          this.form.setFieldsValue({
            company_prefecture: '',
            company_city: ''
          });
          this.form.setFields({
            postalcode: { value, errors: [new Error(this.$t('There is no applicable address.'))] }
          });
        }
        this.loading = false;
      });
    }
  }
};
